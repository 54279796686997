
.payment-content {

	padding-top: 20px;
	padding-bottom: 20px;
	height:100%;
	min-height: 350px;

	@media @wide-enough-for-left-column {
		min-height: 540px;
	}


	//.center-content-block();
	padding-top: 10%;
	padding-bottom: 10%;


	position: relative;

	.payment-workbench {

		width: 100%;
		text-align: center;

		&.ng-enter {
			position: absolute;
		}

		// when this content area grows
		// center the content to the middle
		//.center-content-block-container();
	}


	.payment-next-step-button {
		margin-top: 25px;
		font-weight: 600;
	}

	input, select {
		font-size:2rem;
	}

	button {
		font-size: 14px;
		line-height: 18px;
		padding-top: 15px;
		padding-bottom: 15px;
		white-space: normal;
		font-weight: 400;
	}

	.button-group {
		margin-bottom: 25px;

		button {
			height: auto;
			font-size: 12px;
			font-weight: 600;
			padding-top: 12px;
			padding-bottom: 12px;
		}
	}


	select {
		min-width: 100%;
	}

	@media @wider-than-phablet {
		form {
			min-width: 60%;
			max-width: 60%;
			margin-left: auto;
			margin-right: auto;

			// bind button widths to the form
			button {
				min-width: 100%;
				max-width: 100%;
			}
		}

		button,
		.payment-date,
		.interaction-feedback {
			min-width: 75%;
			max-width: 75%;
		}
	}


	.listing-items {
		padding-left: 0;
	}
	.listing .listing-item {
		text-align: left;
		padding-left: 15px;
		text-indent: 0;
		cursor: pointer;


		&.errored {
			&,
			.listing-item-secondary-desc {
				color: @global-color-warning;
			}

			.iconic * {
				fill: @global-color-warning;
			}
		}
	}

	.listing-items .listing-item-icon .iconic {
		fill: @global-color-secondary;
		transition: transform ease 0.2s;
		transform-origin: 50% 50%;
	}

	.listing-items .input-area-wrapper a {
		color: @global-color-link;
	}

	// confirmation listing elements
	@media @wider-than-phablet {
		.listing:hover {
			.listing-item .iconic{
			}
			.listing-item:hover .iconic{
				transform: scale(1.5);
			}
		}

		.listing .listing-item {
			padding-left: 75px;
			margin-bottom: 15px;
		}

		.listing .listing-item.terms-of-service {
			margin-right: 50px;
		}
	}

	.listing .listing-item.terms-of-service {
		margin-left: -55px;
		padding-bottom: 0px;
	}

	.payment-date {
		.u-center();
		display: none;
		@media @wider-than-phablet {
			display: inline;
		}
	}

	.date-interaction-feedback {
		clear: both;
		padding-top: 20px;
		margin-bottom: -20px;
	}

	.method-step .interaction-feedback {
		padding-left: 0px;
		padding-right: 0px;
	}

	/* make the cc icon is legible */
	[for="cc_number"] .iconic {
		height: 35px;
		width: 35px;
		margin-top: 7px;
	}


	[credit-card],
	[expiry] {
		word-spacing: 5px;
	}
}

.flow-question {
	font-size: 1.9rem;
	line-height: 2.6rem;
	text-align: center;
	margin-bottom: 25px;

	@media @wider-than-phablet {
		font-size: 2.5rem;
	}
}


.recaptcha-disclaimer {
	text-align: left;
	margin: 0 auto;

	& a {
		color: @global-color-dark-grey;
	}

	@media @wider-than-phablet {
		max-width: 75%;
	}
}

/***************
LEFT COLUMN
***************/
.payment-left-column, .payment-left-column-persist {
	display: none;

	@media @wide-enough-for-left-column {
		display: block;
	}
}

.payment-progress {
	padding: 5% 0 0;

	@media @wide-enough-for-left-column {
		display: none;
	}
}


/***************
HEADER
***************/
.payment-header {
	.global-z-index-for('payment-header');
	position: absolute;
	top:10px;
	color: #fff;

	@media @wide-enough-for-left-column {
		display: none;
	}
}


/**************
Apple Card Scann Inputs
**************/
.apple-scann-exp {
	width: 1px;
	height: 1px;
	margin-left: -999999px;
	position: fixed;
	display: block;
}



/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

form {
	margin-bottom: 0;
}

textarea:focus,
select:focus {
	color: @global-color-input-text;
	border:none;
	border-bottom: 1px solid @global-color-secondary;
	outline: 0;
}

label,
legend {
	display: block;
	margin-bottom: .5rem;
	font-weight: 600;
}

fieldset {
	padding: 0;
	border-width: 0;
}

select {
	border: none;
	border-bottom: 1px solid #D1D1D1;
	border-radius: 0;
	color: @global-color-input-text;

	&.empty-value {
		color: #a9a9a9; // webkit placeholder text color
	}
}

textarea,
select {
	width: 96%;
	.global-normal-weight-font();
}

textarea {
	background: #fafafa;
	min-height: 125px;
	border: 0;
	.global-normal-weight-font();
}

input {

	&[type="email"],
	&[type="number"],
	&[type="search"],
	&[type="text"],
	&[type="tel"],
	&[type="url"],
	&[type="password"] {
		min-width: 96%;
		//height: 38px;
		padding: 12px 10px; // The 6px vertically centers text on FF, ignored by Webkit
		background-color: #fff;
		color: @global-color-input-text;
		border:none;
		border-bottom: 1px solid lighten(@global-color-border, 8.8%);
		box-shadow: none;
		box-sizing: border-box;
		word-spacing: 1px;
		.global-normal-weight-font();
	}

	&[type="checkbox"],
	&[type="radio"] {
		display: inline-block;
		margin:8px 15px;
	}


	&[type="email"]:focus,
	&[type="number"]:focus,
	&[type="search"]:focus,
	&[type="text"]:focus,
	&[type="tel"]:focus,
	&[type="url"]:focus,
	&[type="password"]:focus {
		border-color: @global-color-secondary;
		outline: 0;
	}
}

label {
	display: inline;
	font-weight: normal;
	cursor: pointer;
}


.split-inputs-row {
	&:after {
		.u-cf();
	}

	.input-area-wrapper {
		.grid-column-width(2);
	}
}



/*******************
INPUT AREA STRUCTURE
*******************/

.split-input-areas-container {
	min-width: 100%;

	@media @wider-than-phablet {
		&:after {
			.u-cf();
		}

		.input-area-wrapper {
			float: left;
			width: 50%;

            input {
                width: 100%;

                &.input-with-icon {
                    min-width: 0;
                    width: 90%;
                }
            }

			&:first-child {
				input.input-with-icon {
					width: 75%;
				}
			}
		}
	}
}

.input-area-wrapper {

	&:after {
		.u-cf();
	}

	background: #fff;
	border-radius: @global-radius;
	margin: 0 auto 1.5rem;
	text-align: left;

	// we remove the margin bottom because most
	// input fields are on a white background and you can't
	// see the use of the padding on the feedback
	// so we will default to that experience and
	// per module that is not on a white bg we will
	// update in that module .less styling
	&.errored {
		margin-bottom: 0;
	}
}

.input-area-field-wrapper {

	border-radius: @global-radius @global-radius 0 0;

	border-bottom: 1px solid lighten(@global-color-border, 8.8%);

	.input-focused & {
		border-bottom-color: @global-color-secondary;
	}

	&.no-initial-border {
		border-bottom-width: 0;
	}

	&.inline-input-type {
		padding-top: 15px;
		padding-bottom: 10px;
		input {
			margin-right: 10px;
		}
	}

	.errored & {
		border-bottom: 2px solid @global-color-warning;
	}

	input,
	select {
		display: block;
		min-width: 0;
		margin: 0 0 0.1px 0; // bottom margin needed to show border on Firefox
		border: 0;
		border-radius: 0;
		min-width: 99%;
		&.input-with-icon,
		&.input-with-right-icon {
			min-width: 88%;
			border: 0;
		}

		&.input-with-right-icon {
			min-width: 83%;
			width: 83%;
		}

		&[type="checkbox"],
		&[type="radio"] {
			display: inline-block;
			min-width: 0;
		}
	}

	.iconic {
		float: left;
		width: 20px;
		height: 20px;
		margin: 11px 0 0 10px;

		&.icon-for-input-pull-right {
			float:right;
		}
	}
}

input[type="checkbox"] {
    opacity: 0;
    height: 0;

	+ label {
	    position: relative;
	    display: inline-block;
	    cursor: pointer;
	    padding-left: 41px;
	}

	+ label::before,
	+ label::after {
	    position: absolute;
	    content: "";
	    display: inline-block;
	}

	/*Outer box of the fake checkbox*/
	+ label::before{
	    height: 16px;
	    width: 16px;
	    border: 1px solid #9e9e9e;
	    left: 11px;
	    top: 3px;
	}

	/*Checkmark of the fake checkbox*/
	+ label::after {
	    height: 5px;
	    width: 9px;
	    border-left: 2px solid;
	    border-bottom: 2px solid;
	    transform: rotate(-45deg);
	    left: 15px;
	    top: 7px;

		/*Hide the checkmark by default*/
	    content: none;
	}

	&:disabled + label {
		cursor: not-allowed;

		&:before {
	    	background-color: #eee;
    		border-color: #ccc;
		}

		&:after {
			color: #bbb;
		}
	}

	/*Unhide on the checked state*/
	&:checked + label::after {
	    content: "";
	}

	/*Adding focus styles on the outer-box of the fake checkbox*/
	&:focus + label::before {
	    outline: rgb(59, 153, 252) auto 5px;
	}
}

.input-area-feedback {

	display: none;
	padding: 11px 13px;
	text-align: left;

	.errored & {
		display: block;
	}

	[ng-messages] {
		display: none;
		// background: #fff; /*needed for none white backgrounds*/
		// border-radius: 0 0 @global-radius @global-radius;
		// margin: 0 auto;

		width: 200px;
		max-width: 98%;
		min-width: 98%;

		.errored &.ng-active {
			display: block;
		}
	}

	// the error messages themselves
	[ng-messages] [ng-message] {

		.global-normal-weight-font();

		display: block;
		text-align: left;
		color: @global-color-warning;
		font-size: 1.3rem;

		// word-break: break-all;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}
}

// input area explanations are the bits of information
// that is checked against upon user's input
.input-area-rules {

	@default-color: darken(@global-color-secondary-light, 20%);
	@met-color: darken(@global-color-primary, 0%);

	ul {
		.no-list-style();
		text-align: left;

		// we need to give some padding to the rules
		// but we don't want to push the content down any further
		// than is needed.
		position: relative;
  		top: 10px;
	}

	li {
		color: @default-color;
		.global-normal-weight-font();

		&:after {
			.u-cf();
		}

		&.rule-met {
			color: @met-color;
		}
	}

	.rule-met-icon {
		float: left;

		.iconic {
			width: 12px;
			height: 12px;
			margin: -3px 15px 0;
			* {
				fill: @default-color;
			}
		}
	}

	.errored & {
		ul {
			position: static;
			padding-top: 10px;
		}

		li {
			color: @global-color-warning;
			&.rule-met {
				color: @met-color;
			}
		}
		.iconic * {
			fill: @global-color-warning;
		}
	}

	.rule-met .iconic *{
		fill: @met-color;
	}
}


// interaction feedback is the feedback that is presented when a
// server state happens that needs to be displayed to the user -- like bill not found.
// As opposed to an input field validation that is directly tied to an input field
.interaction-feedback {

	.global-normal-weight-font();

	background: #fff;
	padding: 11px 13px;
	border-radius: @global-radius;
	width: 200px;
	max-width: 100%;
	min-width: 100%;
	text-align: left;
	color: @global-color-warning;
	font-size: 14px;
	margin-left: auto;
	margin-right: auto;


	// if this is utilizing messages, only
	// show it when it is need active
	&[ng-messages].ng-inactive {
		display: none;
	}

	.positive-feedback {
		color: @global-color-primary;
	}
}

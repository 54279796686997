body.guest-pay {

	.landing-entry-container {
		background: @global-color-primary;
	}

	._720kb-datepicker-calendar-day._720kb-datepicker-active {
		background-color: @global-color-guestpay-secondary;
		color: #fff;

		&:hover {
			color: #fff;
			background-color: darken(@global-color-guestpay-secondary, 10%);
		}

		&:focus, &:active {
			color: #fff;
			background-color: darken(@global-color-guestpay-secondary, 15%);
		}
	}

	textarea:focus,
	select:focus {
		border-bottom: 1px solid @global-color-guestpay-secondary;
	}

	input {
		&[type="email"]:focus,
		&[type="number"]:focus,
		&[type="search"]:focus,
		&[type="text"]:focus,
		&[type="tel"]:focus,
		&[type="url"]:focus,
		&[type="password"]:focus {
			border-color: @global-color-guestpay-secondary;
		}
	}

	.input-focused .input-area-field-wrapper {
		border-bottom-color: @global-color-guestpay-secondary;
	}

	.input-area-rules {

		@default-color: darken(@global-color-guestpay-secondary-light, 20%);

		li {
			color: @default-color;
		}

		.rule-met-icon {

			.iconic {
				* {
					fill: @default-color;
				}
			}
		}
	}

	.modal-content-container {
		.information & {
			background-color: @global-color-guestpay-secondary;
		}
	}

	.notification {
	    background: @global-color-guestpay-secondary-light;
		border: 1px solid @global-color-guestpay-secondary-light;
	}

	.notification-close:hover {
		fill: darken(@global-color-guestpay-secondary-light, 35%);
	}

	.listing-items .listing-item-icon .iconic {
		fill: @global-color-guestpay-secondary;
	}

	a,
	.link {
		color: @global-color-guestpay-link;

		&:hover {
			color: darken(@global-color-guestpay-link, 5%);
		}
	}

	.header {
		background: @global-color-primary;
	}

	#footer:before {
		background-color: @global-color-primary-light;
	}

	.payment-content {

		.listing-items .input-area-wrapper a {
			color: @global-color-guestpay-link;
		}
	}

	.button,
	button, 
	input[type="submit"],
	input[type="reset"],
	input[type="button"] {
		.global-button-colors(@global-color-guestpay-secondary)
	}
}

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.global-button-colors(@button-color-secondary) {

	color: @button-color-secondary;
	background-color: transparent;
	border: 1px solid @button-color-secondary;
	
	&:hover {
		color: darken(@button-color-secondary, 15%);
		border-color: darken(@button-color-secondary, 15%);
		outline: 0;
	}

	&:focus, &:active {
		color: darken(@button-color-secondary, 25%);
		border-color: darken(@button-color-secondary, 27%);
		outline: 0;
	}

	//--------------------
	// Special states
	//--------------------
	&.button-primary {
		color: #fff;
		background-color: @button-color-secondary;
		border-color: @button-color-secondary;

		&.button-against-dark-bg {
			border-color: darken(@button-color-secondary, 8%);

			&:hover {
				border-color: darken(@button-color-secondary, 25%);
			}
		}

		&:hover {
			color: #fff;
			background-color: darken(@button-color-secondary, 10%);
			border-color: darken(@button-color-secondary, 13%);
		}

		&:focus, &:active {
			color: #fff;
			background-color: darken(@button-color-secondary, 15%);
			border-color: darken(@button-color-secondary, 20%);
		}
	}

	&.button-warn {
		color: #fff;
		background-color: @global-color-warning;
		border-color: @global-color-warning;

		&:hover {
			color: #fff;
			background-color: darken(@global-color-warning, 10%);
			border-color: darken(@global-color-warning, 10%);
		}

		&:focus, &:active {
			color: #fff;
			background-color: darken(@global-color-warning, 15%);
			border-color: darken(@global-color-warning, 15%);
		}
	}

	&.button-warn-white-out {
        color: @global-color-warning;
        border-color: @global-color-warning;

        &:hover {
            color: darken(@global-color-warning, 15%);
            border-color: darken(@global-color-warning, 15%);
        }

        &:focus, &:active {
            color: darken(@global-color-warning, 25%);
            border-color: darken(@global-color-warning, 27%);
        }
    }

	&.button-grey-out {
		color: #fff;
		background-color: darken(@global-color-light-grey, 10%);
		border-color: @global-color-border;

		&:hover {
			color: #fff;
			background-color: darken(@global-color-light-grey, 20%);
			border-color: darken(@global-color-light-grey, 20%);
		}

		&:focus, &:active {
			color: #fff;
			background-color: darken(@global-color-light-grey, 30%);
			border-color: darken(@global-color-light-grey, 32%);
		}
	}

	&.button-white-out {
		color: #fff;
		background-color: transparent;
		border-color: #fff;

		&:hover {
			color: #fff;
			background-color: transparent;
			border-color: #ddd;
		}

		&:focus, &:active {
			color: #fff;
			background-color: transparent;
			border-color: #e0e0e0;
		}
	}

	&.button-against-dark-grey-bg {
		color: @global-color-dark-grey;
		background-color: transparent;
		border-color: @global-color-dark-grey;

		&:hover {
			color: darken(@global-color-dark-grey, 20%);
			background-color: transparent;
			border-color: darken(@global-color-dark-grey, 20%);
		}

		&:focus, &:active {
			color: darken(@global-color-dark-grey, 25%);
			background-color: transparent;
			border-color: darken(@global-color-dark-grey, 25%);
		}
	}

	&[disabled] {
		&,
		&:hover,
		&:focus,
		&:active {
			background-color: @global-color-light-grey;
			color: @global-color-main-grey;
			border-color: @global-color-light-grey;
		}
	}

	&.button-subdued {
		color: @global-color-dark-grey;
		font-weight: 400;
		background-color: @global-color-light-grey;
		border-color: @global-color-light-grey;
		margin-bottom: 0rem;
		line-height: 24px;

		&:hover {
			color: #fff;
			background-color: darken(@global-color-light-grey, 20%);
			border-color: darken(@global-color-light-grey, 20%);
		}

		&:focus, &:active {
			color: #fff;
			background-color: darken(@global-color-light-grey, 30%);
			border-color: darken(@global-color-light-grey, 32%);
		}
	}
}

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {

	.global-normal-weight-font();

	transition: all ease 100ms;

	display: inline-block;
	// when changing this height stuff
	// checkout nav menu button heights
	// height: 45px;
	// max-height: 45px;
	padding: 0 30px;
	margin-bottom: 1rem;
	min-width: 100%;

	text-align: center;
	font-size: 12px;
	font-weight: 600;
	line-height: 38px;
	letter-spacing: .1rem;
	text-transform: none;
	text-decoration: none;
	white-space: nowrap;
	border-radius: @global-radius-buttons;
	cursor: pointer;
	box-sizing: border-box;

	//--------------------
	// Special sizes
	//--------------------
	&.button-lrg {
		font-size: 14px;
		height: 50px;
	}
	&.button-sml {
		height: 30px;
		line-height: 30px;
	}

	//--------------------
	// Sizing/Layout modifiers
	//--------------------
	&.button-callout {
		text-transform: none;
		padding: 5px;
		font-size: 1.5rem;
	}
	&.button-inline {
		min-width: inherit;
	}


	.button-group & {
		display: inline;
		width: auto;
		min-width: 0 !important;
		margin-left: -6px;
		margin-right: 0;
		// border-radius: @global-radius @global-radius 0 0;

		&:first-child {
			// border-radius: 0 0 @global-radius @global-radius;
		}
	}

	.global-button-colors(@global-color-secondary)
}

.button-group {
	
	//offset the button negative margin
	margin-left:3px;

	width: 100%;
	&:after{
		.u-cf();	
	}
}

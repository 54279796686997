@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.error-content {
    min-height: 85vh;
	@media @wider-than-tablet {
        min-height: 650px;
	}
}



.error-container {
    padding: 50px @side-padding ;
    font-size: 2rem;
    color: darken(@global-color-light-grey, 40%);

    @media @wider-than-tablet {
        animation-name: fadeInDown;
        animation-fill-mode: both;
        animation-duration: 0.9s;
        animation-delay:0.2s;
        font-size: 2.4rem;
        clear:right;
        width: 75%;
        padding-top: 25%;
    }

    h1 {
        font-size: 7rem;
        font-weight: 600;
        margin-bottom: 0;

        span {
            font-size: 4rem;
        }
    }
}
